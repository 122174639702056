import HL_01 from '../../assets/posters/Hello_Lucy/HL_01.jpg';
import HL_02 from '../../assets/posters/Hello_Lucy/HL_02.jpg';
import HL_03 from '../../assets/posters/Hello_Lucy/HL_03.jpg';

// neoclassicism
import NC_01 from '../../assets/posters/Neoclassicism/neoclassicism_1.jpg';
import NC_02 from '../../assets/posters/Neoclassicism/neoclassicism_2.jpg';
import NC_03 from '../../assets/posters/Neoclassicism/neoclassicism_3.jpg';
import NC_04 from '../../assets/posters/Neoclassicism/neoclassicism_4.jpg';
import NC_05 from '../../assets/posters/Neoclassicism/neoclassicism_5.jpg';

// Illicit
import IL_01 from '../../assets/posters/Illicit/ILLCT_01.jpg';
import IL_02 from '../../assets/posters/Illicit/ILLCT_02.jpg';
import IL_03 from '../../assets/posters/Illicit/ILLCT_03.jpg';

// New_JDM
import JDM_01 from '../../assets/posters/New_JDM/JDM_01.jpg';
import JDM_02 from '../../assets/posters/New_JDM/JDM_02.jpg';
import JDM_03 from '../../assets/posters/New_JDM/JDM_003.jpg';

// Psilocobalt
import PC_01 from '../../assets/posters/Psilocobalt/PSLCBLT_01.jpg';
import PC_02 from '../../assets/posters/Psilocobalt/PSLCBLT_04.jpg';
import PC_03 from '../../assets/posters/Psilocobalt/PSLCBLT_05.jpg';

// Planetoid
import PL_01 from '../../assets/posters/Planetoid/PL_1.jpg';
import PL_02 from '../../assets/posters/Planetoid/PL_2.jpg';
import PL_03 from '../../assets/posters/Planetoid/PL_3.jpg';

import SH_1 from '../../assets/posters/1.jpg';
import SH_2 from '../../assets/posters/2.jpg';
import SH_3 from '../../assets/posters/3.jpg';

import FALCON from '../../assets/posters/Falcon1_site.jpg';
import JFM from '../../assets/posters/JFM_site.jpg';
import TMOH from '../../assets/posters/TMOH_site.jpg';

const posterItems = [
	{
		posterSrc: FALCON,
		collectionLogoSrc: null,
		text: 'The Falcon_69'
	},
	{
		posterSrc: TMOH,
		collectionLogoSrc: null,
		text: 'The Moments Of Happines'
	},
	{
		posterSrc: JFM,
		collectionLogoSrc: null,
		text: 'JFM_1'
	},
	{
		posterSrc: NC_01,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #001'
	},
	{
		posterSrc: NC_05,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #002'
	},
	{
		posterSrc: NC_04,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #003'
	},
	{
		posterSrc: NC_02,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #004'
	},
	{
		posterSrc: NC_03,
		collectionLogoSrc: null,
		text: 'Neoclassicism collection #005'
	},
	{
		posterSrc: IL_01,
		collectionLogoSrc: null,
		text: 'Illicit collection #001'
	},
	{
		posterSrc: IL_02,
		collectionLogoSrc: null,
		text: 'Illicit collection #002'
	},
	{
		posterSrc: IL_03,
		collectionLogoSrc: null,
		text: 'Illicit collection #003'
	},
	{
		posterSrc: JDM_01,
		collectionLogoSrc: null,
		text: 'JDM collection #001'
	},
	{
		posterSrc: JDM_02,
		collectionLogoSrc: null,
		text: 'JDM collection #002'
	},
	{
		posterSrc: JDM_03,
		collectionLogoSrc: null,
		text: 'JDM collection #003'
	},
	{
		posterSrc: PL_03,
		collectionLogoSrc: null,
		text: 'Planetoid collection #003'
	},
	{
		posterSrc: PL_01,
		collectionLogoSrc: null,
		text: 'Planetoid collection #001'
	},
	{
		posterSrc: PL_02,
		collectionLogoSrc: null,
		text: 'Planetoid collection #002'
	},
	{
		posterSrc: PC_01,
		collectionLogoSrc: null,
		text: 'Psilocobalt collection #001'
	},
	{
		posterSrc: PC_02,
		collectionLogoSrc: null,
		text: 'Psilocobalt collection #002'
	},
	{
		posterSrc: PC_03,
		collectionLogoSrc: null,
		text: 'Psilocobalt collection #003'
	},
	{
		posterSrc: SH_1,
		collectionLogoSrc: null,
		text: 'Shinigami collection #001'
	},
	{
		posterSrc: SH_2,
		collectionLogoSrc: null,
		text: 'Shinigami collection #002'
	},
	{
		posterSrc: SH_3,
		collectionLogoSrc: null,
		text: 'Shinigami collection #003'
	},
	{
		posterSrc: HL_01,
		collectionLogoSrc: null,
		text: 'HelloLucy collection #001'
	},
	{
		posterSrc: HL_02,
		collectionLogoSrc: null,
		text: 'HelloLucy collection #002'
	},
	{
		posterSrc: HL_03,
		collectionLogoSrc: null,
		text: 'HelloLucy collection #003'
	},
];

export default posterItems;